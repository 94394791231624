import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var useStatClientAnswersPermissions = function (user) {
    var hasPermissionListStatClientAnswer = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.STAT_CLIENT_RESPONSE, MethodCode.LIST);
    var hasPermissionEditStatClientAnswer = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.STAT_CLIENT_RESPONSE, MethodCode.EDIT);
    var hasPermissionViewStatClientAnswer = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.STAT_CLIENT_RESPONSE, MethodCode.VIEW);
    var hasPermissionDeleteStatClientAnswer = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.STAT_CLIENT_RESPONSE, MethodCode.DELETE);
    var hasPermissionCreateStatClientAnswer = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.STAT_CLIENT_RESPONSE, MethodCode.CREATE);
    return {
        hasPermissionListStatClientAnswer: hasPermissionListStatClientAnswer,
        hasPermissionEditStatClientAnswer: hasPermissionEditStatClientAnswer,
        hasPermissionViewStatClientAnswer: hasPermissionViewStatClientAnswer,
        hasPermissionDeleteStatClientAnswer: hasPermissionDeleteStatClientAnswer,
        hasPermissionCreateStatClientAnswer: hasPermissionCreateStatClientAnswer,
    };
};
export default useStatClientAnswersPermissions;
